<template>
  <b-row class="justify-content-center">
    <b-col md="6" xl="6">
      <b-card title="Detail Bukti Aktivasi">
        <b-overlay :show="loading" rounded="sm">
          <b-form-group label="Tipe Aktivasi" label-for="activation_type">
            <b-form-select
              v-model="form.type"
              :state="state.type"
              :options="typeOptions"
            />
          </b-form-group>
          <b-row class="mt-2">
            <b-col>
              <b-form-group
                label="Bukti Foto"
                label-for="image"
                class="input-image"
              >
                <div class="text-center">
                  <image-uploader
                    :preview="true"
                    :className="[
                      'fileinput',
                      'custom-file-input',
                      { 'fileinput--loaded': hasImage },
                    ]"
                    :quality="0.5"
                    :maxSize="2048"
                    :debug="0"
                    :autoRotate="true"
                    outputFormat="string"
                    @input="setImage"
                  >
                    <label for="fileInput" slot="upload-label" class="d-block">
                      <figure>
                        <feather-icon icon="CameraIcon" size="35" />
                      </figure>
                      <span class="upload-caption">{{
                        hasImage ? "Replace" : "Click to upload"
                      }}</span>
                    </label>
                  </image-uploader>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button block class="mt-2" variant="success" @click="onSend"
            >Kirim</b-button
          >
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormFile,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import ImageUploader from "vue-image-upload-resize";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormFile,
    flatPickr,
    ImageUploader,
    BOverlay,
    BFormSelect,
  },
  setup() {
    const { baseUrl } = $themeConfig.app;
    return {
      baseUrl,
    };
  },
  data() {
    return {
      loading: false,
      pemrekId: null,
      hasImage: false,
      state: {
        report_id: null,
        type: 1,
        image: null,
      },
      form: {
        report_id: null,
        type: 1,
        image: null,
      },
      typeOptions: [
        { value: 1, text: "Transaksi" },
        { value: 2, text: "Saldo Rata-rata" },
      ],
    };
  },
  created() {
    this.pemrekId = this.$store.state.app.pemrekId;
    this.form.report_id = this.$store.state.app.pemrekId;
    this.state.report_id = this.$store.state.app.pemrekId;
    if (this.$store.state.app.pemrekId === null) {
      this.pemrekId = localStorage.getItem("pemrekId", this.pemrekId);
      this.form.report_id = localStorage.getItem("pemrekId", this.pemrekId);
      this.state.report_id = localStorage.getItem("pemrekId", this.pemrekId);
    }
  },
  methods: {
    setImage(output) {
      this.hasImage = true;
      this.form.image = output;
    },
    validation() {
      if (this.form.type == 0) {
        this.$bvToast.toast("Tipe aktifasi harus dipilih", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }
      if (this.form.image == null) {
        this.$bvToast.toast("Bukti foto tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.type > 0 && this.form.image != null) {
        return true;
      }
    },
    onSend() {
      if (this.validation()) {
        this.loading = true;
        this.$http
          .post("/report-aladin/activation/add", this.form)
          .then((res) => {
            this.loading = false;
            this.$bvToast.toast("Berhasil", {
              title: "Berhasil",
              variant: "success",
              solid: true,
              autoHideDelay: 1000,
            });
            this.$router.push("/pemrek-aladin-verify");
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            this.$bvToast.toast(err.response.data.message, {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 1000,
            });
          });
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
